import { render, staticRenderFns } from "./DetailCoursesRequest.vue?vue&type=template&id=8ce47fbe&"
import script from "./DetailCoursesRequest.vue?vue&type=script&lang=ts&"
export * from "./DetailCoursesRequest.vue?vue&type=script&lang=ts&"
import style0 from "./DetailCoursesRequest.vue?vue&type=style&index=0&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VTab,VTabItem,VTabsItems})
